import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { Container } from "react-grid-system";
import styled from "styled-components";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import AppLayout from "../../components/AppLayout";
import { H1 } from "../../components/Typography";
import api from "../../api";
import Spacer from "../../components/Spacer";
import { colors } from "../../config/styles";
import BackButton from "../../components/BackButton";
import ButtonComponent from "../../components/Button";
import { ErrorResponse, Response } from "../../typings/response";
import "./style.css";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
const Table = styled.table`
  width: 100%;
  max-width: 800px;

  th {
    padding-bottom: 10px;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  tr:not(:first-child) {
    border-radius: 5px;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const UserDetails: React.FunctionComponent = () => {
  const params = useParams<{ userId: string }>();
  const { data: response } = useQuery([params.userId], api.user.getUserDetails);
  // const { data: response2 } = useQuery([params.userId], api.user.activateUserById);

  const [activateUser, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.activateUserById);

  const [deactivateUser, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.deactivateUserById);

  const [resetUserFormById, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.resetUserFormById);

  const [resetUserGameById, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.resetUserGameById);

  const [deleteUserById, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.deleteUserById);

  //

  const [resentUserVerifyLink, {}] = useMutation<
    Response<{ success: boolean }>,
    any,
    ErrorResponse
  >(api.user.resentUserVerificatoinLinkById);

  const [user, setUser] = useState(response?.data);
  const responses = response?.data;

  const [openSnackbarPopup, setOpenSnackbarPopup] = React.useState(false);
  const [openResetFormModal, setOpenResetFormModal] = React.useState(false);
  const [openSessionDataModal, setOpenSessionDataModal] = React.useState(false);
  const [openUserDeleteModal, setOpenUserDeleteModal] = React.useState(false);
  const [snackbarMessagetext, setSnackbarMessagetext] = React.useState("");

  const handleOpenResetFormModal = () => setOpenResetFormModal(true);
  const handleCloseResetFormModal = () => setOpenUserDeleteModal(false);
  const handleCloseUserDeleteModal = () => setOpenUserDeleteModal(false);
  const styleForReset = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const updateUserInformation = () => {
    setUser(response?.data);
  };
  if (user == undefined && response?.data != undefined) {
    updateUserInformation();
  }
  // const activateUser = (id) =>{
  //       alert("boom");
  //       // const resp = useQuery([id], api.user.activateUserById);
  // }

  const activateUserClick = async (id) => {
    // e.preventDefault();
    try {
      const response = await activateUser(id);
      setUser(response.data);
      setSnackbarMessagetext("User is Activited");
      setOpenSnackbarPopup(true);
      if (response.data.success) {
        // setEmailChecked(true);
        alert("activated");
      }
    } catch (err) {
      console.warn(err);
    }
  };
  const deactivateUserClick = async (id) => {
    // e.preventDefault();
    try {
      const response = await deactivateUser(id);
      setUser(response.data);
      setSnackbarMessagetext("User is De-activited");
      setOpenSnackbarPopup(true);
      if (response.data.success) {
        // setEmailChecked(true);
        alert("activated");
      }
    } catch (err) {
      console.warn(err);
    }
  };
  const resetingUserForm = async (id) => {
    // e.preventDefault();
    try {
      const response = await resetUserFormById(id);
      handleCloseResetFormModal();
      setSnackbarMessagetext("User question answers has been deleted");
      setOpenSnackbarPopup(true);
    } catch (err) {
      setSnackbarMessagetext("Something went wrong");
      console.warn(err);
    }
  };

  const deletingUserData = async (id) => {
    // e.preventDefault();
    try {
      const response = await deleteUserById(id);
      handleCloseUserDeleteModal();
      setSnackbarMessagetext("User details has been deleted");
      setOpenSnackbarPopup(true);
    } catch (err) {
      setSnackbarMessagetext("Something went wrong");
      console.warn(err);
    }
  };

  const resetingUserGame = async (id) => {
    // e.preventDefault();
    try {
      const response = await resetUserGameById(id);
      setSnackbarMessagetext("User session has been deleted");
      setOpenSnackbarPopup(true);
      setOpenSessionDataModal(false);
    } catch (err) {
      setSnackbarMessagetext("Something went wrong");
      console.warn(err);
    }
  };
  const resentMailForVerification = async (id) => {
    // e.preventDefault();
    try {
      const response = await resentUserVerifyLink(id);

      setSnackbarMessagetext("Verification mail has been sent");
      setOpenSnackbarPopup(true);
    } catch (err) {
      console.warn(err);
    }
  };

  return (
    <AppLayout>
      <Modal
        open={openResetFormModal}
        onClose={handleCloseResetFormModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForReset}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            All the answer provided by user will be deleted.
          </Typography>
          <div className="flexEnd">
            <Button
              variant="contained"
              color="success"
              className="roundButton"
              onClick={() => {
                resetingUserForm(params.userId);
                setOpenResetFormModal(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="ml30 roundButton"
              color="error"
              onClick={() => {
                setOpenResetFormModal(false);
              }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openUserDeleteModal}
        onClose={handleCloseUserDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForReset}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            All the User information will be deleted.
          </Typography>
          <div className="flexEnd">
            <Button
              variant="contained"
              color="success"
              className="roundButton"
              onClick={() => {
                deletingUserData(params.userId);
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="ml30 roundButton"
              color="error"
              onClick={() => {
                handleCloseUserDeleteModal();
              }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openSessionDataModal}
        onClose={() => {
          setOpenSessionDataModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleForReset}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            All the session played by user will be deleted.
          </Typography>
          <div className="flexEnd">
            <Button
              variant="contained"
              color="success"
              className="roundButton"
              onClick={() => {
                resetingUserGame(params.userId);
              }}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="ml30 roundButton"
              color="error"
              onClick={() => {
                setOpenSessionDataModal(false);
              }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbarPopup}
        onClose={() => {
          setOpenSnackbarPopup(false);
        }}
        message={snackbarMessagetext}
        key={"top" + "right"}
        autoHideDuration={5000}
      >
        <Alert severity="success">{snackbarMessagetext}</Alert>
      </Snackbar>

      <Container fluid>
        <BackButton path="/app/users" />
        <Spacer bottom={35}>
          <H1>Results for {user?.email}</H1>
        </Spacer>
        <Table cellSpacing="0" cellPadding="0">
          {/* <tr> */}
          <tr>
            <th align="left">First Name :</th>
            <td align="left">{user?.firstName}</td>
          </tr>
          <tr>
            <th align="left">Middle Name :</th>
            <td align="left">{user?.middleInitial}</td>
          </tr>
          <tr>
            <th align="left">Last Name</th>
            <td align="left">{user?.lastName}</td>
          </tr>
          <tr>
            <th align="left">Email :</th>
            <td align="left">{user?.email}</td>
          </tr>
          <tr>
            <th align="left">Is Verified :</th>
            <td align="left">
              {user?.verified ? "Yes" : "No"}
              {user?.verified ? (
                ""
              ) : (
                <span
                  className="resentUserVerificatinLinkText"
                  onClick={() => {
                    resentMailForVerification(params.userId);
                  }}
                >
                  Resend verification mail
                </span>
              )}
            </td>
          </tr>
          <tr>
            <th align="left">Quiz Passed :</th>
            <td align="left">{user?.passedQuiz ? "Yes" : "No"}</td>
          </tr>
          <tr>
            <th align="left">Is Active</th>
            <td align="left">{user?.isActive ? "Yes" : "No"}</td>
          </tr>
          {/* </tr> */}
        </Table>

        <div className="cardSection">
          <div>Make the User {user?.isActive ? "De-active" : "Active"} :</div>
          <div>
            {user?.isActive ? (
              <Button
                className="roundButton"
                variant="contained"
                color="error"
                size="large"
                onClick={() => {
                  deactivateUserClick(params.userId);
                }}
              >
                De-Activate
              </Button>
            ) : (
              <Button
                className="roundButton"
                variant="contained"
                color="success"
                size="large"
                onClick={() => {
                  activateUserClick(params.userId);
                }}
              >
                Activate
              </Button>
            )}
          </div>
        </div>
        {/* adding user question reseting button */}
        <div className="cardSection">
          <div>Reset the User Form Data :</div>
          <div>
            <Button
              variant="contained"
              className="roundButton"
              color="error"
              onClick={() => {
                handleOpenResetFormModal();
              }}
            >
              Reset
            </Button>
          </div>
        </div>
        <div className="cardSection">
          <div>Reset the User Game Data :</div>
          <div>
            <Button
              variant="contained"
              className="roundButton"
              color="error"
              onClick={() => setOpenSessionDataModal(true)}
            >
              Reset
            </Button>
          </div>
        </div>
        <div className="cardSection">
          <div>DELETE User Data :</div>
          <div>
            <Button
              variant="contained"
              className="roundButton"
              color="error"
              onClick={() => {
                setOpenUserDeleteModal(true);
              }}
            >
              DELETE
            </Button>
          </div>
        </div>
      </Container>
    </AppLayout>
  );
};

export default UserDetails;
