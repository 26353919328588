import React, {
  ChangeEvent, FormEvent, useState,
} from 'react';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { colors } from '../../config/styles';
import selectArrow from '../../assets/icons/select-arrows.svg';

interface BaseProps {
  onChange: (value: string) => void;
  value?: string;
  innerRef?: any;
  units?: {
    label: string;
    mask: Array<string|RegExp>,
    minValidLength?: number;
    validLength?: number;
  }[];
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 35px;
  transform: translateX(50%);
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input<BaseProps>`
  transition: .1s ease-in-out;
  border: 1px solid ${colors.border};
  height: 82px;
  width: calc(100% - 50px);
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  color: ${colors.text};
  font-size: 17px;
  padding: 0 190px 0 25px;
  
  &:focus {
    border-color: ${colors.mainGreen};
  }
  
  &::placeholder {
    color: ${colors.placeholder}
  }
`;

const UnitSelect = styled.select`
  position: absolute;
  right: 70px;
  width: 94px;
  height: 44px;
  border-radius: 22px;
  border: 2px solid ${colors.border};
  color: ${colors.border};
  text-align: center;
  text-align-last: center;
  font: 400 17px Roboto;

  &:hover {
    cursor: pointer;
  }
  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const UnitInput: React.FunctionComponent<
BaseProps & React.HTMLProps<HTMLInputElement>
> = (props) => {
  const {
    placeholder, type, units, onChange: propsOnChange, value: propsValue,
  } = props;
  const [value, setValue] = useState(propsValue || '');
  const [selectedUnit, setSelectedUnit] = useState(units[0]);

  const isValueValid = (testString: string) => testString.length >= selectedUnit.minValidLength
    || testString.length === selectedUnit.validLength;


  const onChange = (e: FormEvent<HTMLInputElement>) => {
    const { value: eventValue } = e.currentTarget;

    if (isValueValid(eventValue)) {
      propsOnChange(`${eventValue} ${selectedUnit.label}`);
    }

    if (!isValueValid(eventValue) && isValueValid(value)) {
      propsOnChange('');
    }

    setValue(eventValue);
  };

  const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedUnit(units.find((unit) => unit.label === e.target.value));
  };

  return (
    <InputContainer>
      <InputWrapper>
        <MaskedInput
          guide={false}
          onChange={onChange}
          value={value}
          mask={selectedUnit.mask}
          render={(ref, maskProps) => (
            <StyledInput
              {...maskProps}
              placeholder={placeholder}
              type={type}
              ref={ref}
            />
          )}
        />
        <ArrowIcon src={selectArrow} alt="Select" />
        <UnitSelect onChange={handleUnitChange}>
          {units && units.map((unit) => <option value={unit.label}>{unit.label}</option>)}
        </UnitSelect>
      </InputWrapper>
    </InputContainer>
  );
};

export default UnitInput;
