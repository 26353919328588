import React from 'react';
import { useQuery } from 'react-query';
import { Container } from 'react-grid-system';
import styled from 'styled-components';
import { useParams } from 'react-router';
import moment from 'moment';

import AppLayout from '../../components/AppLayout';
import { H1, P } from '../../components/Typography';
import api from '../../api';
import Spacer from '../../components/Spacer';
import { colors } from '../../config/styles';
import BackButton from '../../components/BackButton';

const Table = styled.table`
  width: 100%;
  max-width: 800px;
  
  th {
    padding-bottom: 10px;
  }
  
  tr {
    th:first-child, td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    
    th:last-child, td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  
  tr:not(:first-child) {
    border-radius: 5px;
  
    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const VideoLink = styled.a`
  color: ${colors.mainGreen};
`;

const GameResults: React.FunctionComponent = () => {
  const params = useParams<{gameId: string}>();
  const { data: response } = useQuery([params.gameId], api.game.getUserGameResults);

  const user = response?.data?.userId;
  const results = response?.data.results;

  return (
    <AppLayout>
      <Container fluid>
        <BackButton path="/app/game-results" />
        <Spacer bottom={35}>
          <H1>
            Results for
            {' '}
            {user?.email}
          </H1>
          <P>{moment(response?.data.createdAt as string).format('LLLL')}</P>
          {response?.data.videoUrl && (
            <Spacer top={20}>
              <VideoLink href={response.data.videoUrl} target="_blank" rel="noreferrer">Watch video</VideoLink>
            </Spacer>
          )}
        </Spacer>
        <Table cellSpacing="0" cellPadding="0">
          <tr>
            <th align="left">Round</th>
            <th align="center">Responses</th>
          </tr>
          {results?.map((roundData) => (
            <tr>
              <td>{roundData.round}</td>
              <td align="center">
                {roundData.responses && roundData.responses
                  .map((press) => `Pressed ${press.pressedKey as string} in ${press.currentCountDown / 1000}s (${press.pressedKey === roundData.gameLetter ? 'correct' : 'incorrect'})`)
                  .join(', ')}
              </td>
            </tr>
          ))}
        </Table>
      </Container>
    </AppLayout>
  );
};

export default GameResults;
