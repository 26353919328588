import { AxiosPromise } from 'axios';
import client from '../core';
import { Question } from '../../typings/questions';
import { QuizResult } from '../../typings/api';

interface ExtendedQuestion {
  _id: string;
}

const getQuizQuestions = (): AxiosPromise<(Question & ExtendedQuestion)[]> => client.get('quiz');

const getQuizQuestion = (id: string): AxiosPromise<Question> => client.get(`quiz/${id}`);

interface GetResultsResponse {
  count: number;
  page: number;
  pages: number;
  results: QuizResult[];
}

const getQuizResults = (page: number): AxiosPromise<GetResultsResponse> => client.get('quiz/results', { params: { page } });
const getFilterQuiz = (page: number, filterType : string, sortOrder : string): AxiosPromise<GetResultsResponse> => client.get('quiz/filter', { params: { page, filterType, sortOrder } });
const createQuizQuestion = (data: Question): AxiosPromise<Question> => client.post('quiz', data);

const saveQuizOrder = (order: string[]): AxiosPromise<Question> => client.post('quiz/order', { order });

const deleteQuizQuestion = (id: string): AxiosPromise<Question> => client.delete(`quiz/${id}`);

const saveQuestion = (id: string, data): AxiosPromise<Question> => client.put(`quiz/${id}`, data);

const getUserQuizResults = (id: string): AxiosPromise<QuizResult> => client.get(`quiz/results/${id}`);

const getQuizResultsCSV = (selectedIds?: string[], page?: number, filterType? : string, sortOrder? : string): AxiosPromise<any> => client.post('quiz/results/csv', {selectedIds}, { params: { page, filterType, sortOrder } });

const getSelectedUserCSV = (selectedIds: string[]): AxiosPromise<any> => client.post('quiz/selectedEmailDownload/csv', { selectedIds });

export default {
  getQuizQuestions,
  getQuizResults,
  getFilterQuiz,
  getUserQuizResults,
  getQuizResultsCSV,
  createQuizQuestion,
  deleteQuizQuestion,
  getQuizQuestion,
  saveQuestion,
  saveQuizOrder,
  getSelectedUserCSV
};
