export enum InputVariants {
  SELECT = 'select',
  TEXT = 'text',
  DATE = 'date',
  WEIGHT = 'weight',
  HEIGHT = 'height',
}

interface CustomInputBase {
  placeholder: string;
  name: string;
  upperlimit? : string;
  lowerlimit? : string;
  commonlyAcceptedLowerLimit? : string;
  commonlyAcceptedUpperLimit? : string;
  confirmationMessage? : string;
  errorMessage? : string;
}

interface CustomInputSelect extends CustomInputBase {
  type: typeof InputVariants.SELECT;
  children: string[];
}

interface CustomInputAny extends CustomInputBase{
  type: typeof InputVariants.TEXT
  | typeof InputVariants.DATE
  | typeof InputVariants.WEIGHT
  | typeof InputVariants.HEIGHT;
}

export type CustomInput = CustomInputSelect | CustomInputAny;

export enum QuestionVariants {
  YES_NO = 'yes_no',
  CUSTOM = 'custom',
  SINGLE_VARIANT = 'single_variant',
  MULTIPLE_CHOICE = 'multiple_choice'
}

interface QuestionBase {
  title: string;
  subtitle?: string;
}

interface QuestionYesNo extends QuestionBase {
  name: string;
  type: typeof QuestionVariants.YES_NO;
}

interface QuestionCustom extends QuestionBase {
  type: typeof QuestionVariants.CUSTOM;
  payload: CustomInput[];
}

interface QuestionSingleVariant extends QuestionBase {
  type: typeof QuestionVariants.SINGLE_VARIANT;
  name: string;
  payload: string[];
  isnoneenable: boolean;
  nonedisplaytext: string;
}

interface QuestionMultipleChoice extends QuestionBase {
  type: typeof QuestionVariants.MULTIPLE_CHOICE;
  name: string;
  payload: string[];
  isnoneenable: boolean;
  nonedisplaytext: string;
}

export type Question = QuestionYesNo | QuestionCustom | QuestionSingleVariant | QuestionMultipleChoice;
