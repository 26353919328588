import React, { FormEvent, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Spacer from '../../components/Spacer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { H1, P } from '../../components/Typography';
import TextLink from '../../components/TextLink';
import api from '../../api';
import { LoginData } from '../../api/auth';
import useErrors from '../../utils/useErrors';
import { ErrorResponse, Response } from '../../typings/response';
import AuthActions from '../../redux/auth/actions';

import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import Header from '../../components/Header';
import Page from '../../components/Page';

const Main: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [mutate, { error, status }] = useMutation<
  Response<{ token: string }>,
  LoginData,
  ErrorResponse
  >(api.auth.login);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const errors = useErrors(error?.response);

  const onButtonClick = async () => {
    try {
      const response = await mutate({
        email,
        password,
      });

      dispatch(AuthActions.authorizeUser(response.data.token));
    } catch (err) {
      console.warn(err);
    }
  };

  const handleInputChange = (
    setter: (value: string) => void,
  ) => (e: FormEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
  };

  const buttonDisabled = !email || !password;

  return (
    <Page>
      <Header />
      <Container>
        <Row>
          <Col sm={6} offset={{ sm: 3 }}>
            <H1 align="center">Sign in to Niji Admin</H1>
            <Spacer top={15}>
              <P size={20} align="center">Welcome back. Enter your details below.</P>
            </Spacer>
            <Spacer top={35}>
              <Input
                error={errors.email}
                placeholder="E-mail"
                onChange={handleInputChange(setEmail)}
                icon={emailIcon}
              />
            </Spacer>
            <Spacer top={15}>
              <Input
                type="password"
                error={errors.password}
                placeholder="Password"
                onChange={handleInputChange(setPassword)}
                icon={passwordIcon}
              />
            </Spacer>
            <Spacer top={25}>
              <P align="center"><TextLink to="/reset">Forgot your password?</TextLink></P>
            </Spacer>
            <Spacer top={45} bottom={45}>
              <Row>
                <Col sm={8} offset={{ sm: 2 }}>
                  <Button
                    loading={status === 'loading'}
                    disabled={buttonDisabled}
                    onClick={onButtonClick}
                  >
                    Sign in
                  </Button>
                </Col>
              </Row>
            </Spacer>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default Main;
