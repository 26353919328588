import React, { useEffect, useState } from "react";
import { usePaginatedQuery } from "react-query";
import moment from "moment";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { useHistory } from "react-router";
import FileDownload from "js-file-download";
import { Checkbox } from "@mui/material";
import AppLayout from "../../components/AppLayout";
import { H1, P } from "../../components/Typography";
import api from "../../api";
import Spacer from "../../components/Spacer";
import ButtonComponent from "../../components/Button";
import { colors } from "../../config/styles";
import { formatUserName } from "../../utils/formatUserName";
import Loading from "../../components/Loading";

const Table = styled.table<{ loading: boolean }>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};

  th {
    padding-bottom: 10px;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  tbody tr {
    border-radius: 5px;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 15px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  ${ButtonsContainer} {
    margin-right: 15px;
  }
`;

const ExportInfo = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 10px;
  }
`;

const FooterContainer = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PAGE_SIZE = 25;

const Users: React.FunctionComponent = () => {
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [showFilterDataFlag, setShowFilterDataFlag] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(true);
  const [filterTypeActive, setFilterTypeActive] = React.useState("");
  const [selectedUsersList, setSelectedUsersList] = React.useState([]);
  const fetchResults = (key, passedPage = 1) => api.user.getUsers(passedPage);
  const [userDataToShow, setUserDataToShow] = React.useState({
    data: { results: [], pages: 0, count: 0 },
  });
  const [isPaginationClicked, setIsPaginationClicked] = useState(false);
  const [sortOrderStatus, setSortOrderStatus] = React.useState({
    name: "off",
    email: "off",
    updatedAt: "off",
  });
  // const {
  //   resolvedData,
  //   isFetching,
  // } = usePaginatedQuery(['results', page], fetchResults);

  const downloadFile = async (queryPage?: number) => {
    let sortOrder = sortOrderStatus[filterTypeActive];
    let response;
    if (sortOrder === "off") {
      response = await api.user.getUserResultsCSV(selectedUsersList, queryPage);
    } else {
      response = await api.user.getUserResultsCSV(
        selectedUsersList,
        queryPage,
        filterTypeActive,
        sortOrder
      );
    }

    FileDownload(response.data.csv, "report.csv");
  };

  const downloadSelectedUsers = async () => {
    // const response = await api.user.getSelectedUserCSV(selectedUsersList);

    const response = await api.user.getUserResultsCSV(selectedUsersList);
    FileDownload(response.data.csv, "report.csv");
  };

  const navigateUserDetails = (userId: string) => {
    history.push(`/app/users/${userId}`);
  };

  const showFilterData = async (filterType: string) => {
    setIsPaginationClicked(false);
    callFilterApI(filterType);
  };

  const nextSortOrderStatus = (status, isPaginationClicked) => {
    if (!isPaginationClicked) {
      switch (status) {
        case "off":
          return "asc";
        case "asc":
          return "desc";
        default:
          return "off";
      }
    }
    setIsPaginationClicked(false);
  };
  const getUserDataSetInit = async () => {
    const response = await api.user.getUsers(page);
    setUserDataToShow(response);
    setShowLoading(false);
  };

  const callFilterApI = async (filterType: string) => {
    if (filterType === "name") {
      sortOrderStatus.email = "off";
      sortOrderStatus.updatedAt = "off";
    } else if (filterType === "email") {
      sortOrderStatus.name = "off";
      sortOrderStatus.updatedAt = "off";
    } else if (filterType === "updatedAt") {
      sortOrderStatus.name = "off";
      sortOrderStatus.email = "off";
    }

    setShowFilterDataFlag(true);
    setFilterTypeActive(filterType);
    setShowLoading(true);

    // const newSortOrderStatus = { ...sortOrderStatus };

    let sortOrder = nextSortOrderStatus(
      sortOrderStatus[filterType],
      isPaginationClicked
    );

    if (!sortOrder) {
      sortOrder = sortOrderStatus[filterType];
    }

    if (sortOrder === "off") {
      setShowFilterDataFlag(false);
      getUserDataSetInit();
    } else {
      const response = await api.user.getFilterusers(
        page,
        filterType,
        sortOrder
      );
      setShowFilterDataFlag(true);
      setShowLoading(false);
      setUserDataToShow(response);
    }

    const tempSortOrder = { ...sortOrderStatus };
    tempSortOrder[filterType] = sortOrder;
    setSortOrderStatus(tempSortOrder);
  };

  const showFilterDataTable = () => {
    if (showFilterDataFlag && userDataToShow?.data) {
      return (
        <>
          {userDataToShow?.data?.results.map((result) => (
            <tr key={result?._id}>
              <td>
                <Checkbox
                  checked={selectedUsersList.includes(result._id)}
                  onClick={() => {
                    addIdToSelectedList(result?._id);
                  }}
                  className="pt-10"
                />
              </td>
              <td>
                {result.firstName != undefined
                  ? `${result.firstName} ${result.lastName}`
                  : "NA"}
              </td>
              <td align="center">{result.email}</td>
              <td align="center">{result.verified ? "Yes" : "No"}</td>
              <td align="center">{result.isActive ? "Yes" : "No"}</td>
              <td align="center">
                {moment(result.updatedAt).format("MM/DD/YYYY")}
              </td>
              <td align="right">
                <ButtonComponent
                  onClick={() => navigateUserDetails(result._id)}
                  link
                  small
                >
                  View
                </ButtonComponent>
              </td>
            </tr>
          ))}
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (showFilterDataFlag) {
      callFilterApI(filterTypeActive).catch((error) => {
        // handle the error here
        console.log(error);
      });
    } else {
      getUserDataSetInit().catch((error) => {
        // handle the error here
        console.log(error);
      });
    }
  }, [page]);

  const addIdToSelectedList = (id) => {
    if (selectedUsersList.includes(id)) {
      setSelectedUsersList(
        selectedUsersList.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedUsersList([...selectedUsersList, id]);
    }
  };
  const toggleSelectAll = () => {
    if (selectedUsersList.length === userDataToShow?.data?.results?.length) {
      setSelectedUsersList([]);
    } else {
      setSelectedUsersList(
        userDataToShow?.data?.results?.map((result) => result._id) || []
      );
    }
  };
  const showFilterIcon = (filterType: string) => {
    if (sortOrderStatus[filterType] === "asc") {
      return (
        <img src="/images/png/up-arrow.png" alt="asc filter" width="10px" />
      );
    }
    if (sortOrderStatus[filterType] === "desc") {
      return (
        <img src="/images/png/down-arrow.png" alt="desc filter" width="10px" />
      );
    }
    return "";
  };
  return (
    <AppLayout>
      <Container fluid>
        <Spacer bottom={35}>
          <H1>Users</H1>
        </Spacer>
        <Row>
          <Col sm={8}>
            {showLoading ? (
              <Loading />
            ) : (
              <Table cellSpacing="0" cellPadding="0" loading={showLoading}>
                <tr>
                  <th align="left">
                    <Checkbox
                      checked={
                        selectedUsersList.length ===
                        userDataToShow?.data?.results?.length
                      }
                      indeterminate={
                        selectedUsersList.length > 0 &&
                        selectedUsersList.length <
                          userDataToShow?.data?.results?.length
                      }
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th
                    align="left"
                    onClick={() => {
                      showFilterData("name");
                    }}
                  >
                    <span className="cursor-pointer">
                      Name{" "}
                      {showFilterDataFlag && filterTypeActive === "name"
                        ? showFilterIcon("name")
                        : ""}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      showFilterData("email");
                    }}
                  >
                    <span className="cursor-pointer">
                      Email{" "}
                      {showFilterDataFlag && filterTypeActive === "email"
                        ? showFilterIcon("email")
                        : ""}
                    </span>
                  </th>
                  <th>Verified</th>
                  <th>Active</th>
                  <th
                    onClick={() => {
                      showFilterData("updatedAt");
                    }}
                  >
                    <span className="cursor-pointer">
                      Date{" "}
                      {showFilterDataFlag && filterTypeActive === "updatedAt"
                        ? showFilterIcon("updatedAt")
                        : ""}
                    </span>
                  </th>
                  <th align="right">Actions</th>
                </tr>

                <tbody>
                  {!showFilterDataFlag &&
                    userDataToShow &&
                    userDataToShow.data.results.map((result) => (
                      <tr key={result?._id}>
                        <td>
                          <Checkbox
                            checked={selectedUsersList.includes(result._id)}
                            onClick={() => {
                              addIdToSelectedList(result._id);
                            }}
                            className="pt-10"
                          />
                        </td>
                        <td>
                          {result.firstName != undefined
                            ? `${result.firstName} ${result.lastName}`
                            : "NA"}
                        </td>
                        <td align="center">{result.email}</td>
                        <td align="center">{result.verified ? "Yes" : "No"}</td>
                        <td align="center">{result.isActive ? "Yes" : "No"}</td>
                        <td align="center">
                          {moment(result.updatedAt).format("MM/DD/YYYY")}
                        </td>
                        <td align="right">
                          <ButtonComponent
                            onClick={() => navigateUserDetails(result._id)}
                            link
                            small
                          >
                            View
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                  {showFilterDataTable()}
                </tbody>
              </Table>
            )}

            <FooterContainer>
              <PaginationContainer>
                <ButtonsContainer>
                  <ButtonComponent
                    disabled={page === 1}
                    onClick={() => {
                      setPage(page - 1);
                      setIsPaginationClicked(true);
                    }}
                    link
                  >
                    {"<"}
                  </ButtonComponent>
                  <ButtonComponent
                    disabled={page === userDataToShow?.data.pages}
                    onClick={() => {
                      setPage(page + 1);
                      setIsPaginationClicked(true);
                    }}
                    link
                  >
                    {">"}
                  </ButtonComponent>
                </ButtonsContainer>
                <P>
                  {(page - 1) * PAGE_SIZE + 1} -{" "}
                  {page === userDataToShow?.data?.pages
                    ? userDataToShow?.data?.count
                    : page * PAGE_SIZE}{" "}
                  of {userDataToShow?.data?.count}
                </P>
              </PaginationContainer>
              <ExportInfo>
                <P>Export as CSV</P>
                {selectedUsersList?.length === 0 && (
                  <>
                    <ButtonComponent
                      link
                      small
                      onClick={() => downloadFile(page)}
                    >
                      This page
                    </ButtonComponent>
                    <ButtonComponent link small onClick={() => downloadFile()}>
                      All data
                    </ButtonComponent>
                  </>
                )}
                {selectedUsersList.length > 0 && (
                  <ButtonComponent
                    link
                    small
                    onClick={() => downloadSelectedUsers()}
                  >
                    Only Selected Users
                  </ButtonComponent>
                )}
              </ExportInfo>
            </FooterContainer>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default Users;
