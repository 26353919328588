import React, { useEffect, useState } from "react";
import { usePaginatedQuery } from "react-query";
import moment from "moment";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { useHistory } from "react-router";
import FileDownload from "js-file-download";
import { Checkbox } from "@mui/material";
import AppLayout from "../../components/AppLayout";
import { H1, P } from "../../components/Typography";
import api from "../../api";
import Spacer from "../../components/Spacer";
import ButtonComponent from "../../components/Button";
import { colors } from "../../config/styles";
import { formatUserName } from "../../utils/formatUserName";
import Loading from "../../components/Loading";

const Table = styled.table<{ loading: boolean }>`
  transition: opacity 0.3s ease-in-out;
  width: 100%;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};

  th {
    padding-bottom: 10px;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  tbody tr {
    border-radius: 5px;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 15px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  ${ButtonsContainer} {
    margin-right: 15px;
  }
`;

const ExportInfo = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 10px;
  }
`;

const FooterContainer = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PAGE_SIZE = 25;

const GameResults: React.FunctionComponent = () => {
  const history = useHistory();
  const [page, setPage] = React.useState(1);

  const fetchResults = (key, passedPage = 1) =>
    api.game.getGameResults(passedPage);
  const [isPaginationClicked, setIsPaginationClicked] = useState(false);

  const [sortOrderStatus, setSortOrderStatus] = React.useState({
    name: "off",
    email: "off",
    answer: "off",
    updatedAt: "off",
  });
  const [showLoading, setShowLoading] = React.useState(true);
  const [filterTypeActive, setFilterTypeActive] = React.useState("");
  const [showFilterDataFlag, setShowFilterDataFlag] = React.useState(false);
  const [userDataToShow, setUserDataToShow] = React.useState({
    data: { results: [], pages: 0, count: 0 },
  });
  const [selectedUsersList, setSelectedUsersList] = React.useState([]);
  const nextSortOrderStatus = (status, isPaginationClicked) => {
    if (!isPaginationClicked) {
      switch (status) {
        case "off":
          return "asc";
        case "asc":
          return "desc";
        default:
          return "off";
      }
    }
    setIsPaginationClicked(false);
  };
  const { resolvedData, isFetching } = usePaginatedQuery(
    ["game-results", page],
    fetchResults
  );

  const downloadFile = async (queryPage?: number) => {
    let sortOrder = sortOrderStatus[filterTypeActive];
    let response;
    if (sortOrder === "off") {
      response = await api.game.getGameResultsCSV(selectedUsersList, queryPage);
    } else {
      response = await api.game.getGameResultsCSV(
        selectedUsersList,
        queryPage,
        filterTypeActive,
        sortOrder
      );
    }

    FileDownload(response.data.csv, "report.csv");
  };

  const navigateUserResults = (userId: string) => {
    history.push(`/app/game-results/${userId}`);
  };
  const getUserDataSetInit = async () => {
    const response = await api.game.getGameResults(page);
    setUserDataToShow(response);
    setShowLoading(false);
  };
  const callFilterApI = async (filterType: string) => {
    if (filterType === "name") {
      sortOrderStatus.email = "off";
      sortOrderStatus.answer = "off";
      sortOrderStatus.updatedAt = "off";
    } else if (filterType === "email") {
      sortOrderStatus.name = "off";
      sortOrderStatus.answer = "off";
      sortOrderStatus.updatedAt = "off";
    } else if (filterType === "answer") {
      sortOrderStatus.email = "off";
      sortOrderStatus.name = "off";
      sortOrderStatus.updatedAt = "off";
    } else if (filterType === "updatedAt") {
      sortOrderStatus.email = "off";
      sortOrderStatus.name = "off";
      sortOrderStatus.answer = "off";
    }
    setFilterTypeActive(filterType);
    setShowLoading(true);
    let sortOrder = nextSortOrderStatus(
      sortOrderStatus[filterType],
      isPaginationClicked
    );
    setShowFilterDataFlag(true);

    if (!sortOrder) {
      sortOrder = sortOrderStatus[filterType];
    }
    if (sortOrder === "off") {
      setShowFilterDataFlag(false);
      getUserDataSetInit();
    } else {
      const response = await api.game.getFilterGamesResult(
        page,
        filterType,
        sortOrder
      );

      setShowLoading(false);
      setUserDataToShow(response);
    }
    const tempSortOrder = { ...sortOrderStatus };
    tempSortOrder[filterType] = sortOrder;
    setSortOrderStatus(tempSortOrder);
  };
  const showFilterData = async (filterType: string) => {
    setIsPaginationClicked(false);
    callFilterApI(filterType);
  };
  const showFilterIcon = (filterType: string) => {
    if (sortOrderStatus[filterType] === "asc") {
      return (
        <img src="/images/png/up-arrow.png" alt="asc filter" width="10px" />
      );
    }
    if (sortOrderStatus[filterType] === "desc") {
      return (
        <img src="/images/png/down-arrow.png" alt="desc filter" width="10px" />
      );
    }

    return "";
  };

  useEffect(() => {
    if (showFilterDataFlag) {
      callFilterApI(filterTypeActive).catch((error) => {
        // handle the error here
        console.log(error);
      });
    } else {
      getUserDataSetInit().catch((error) => {
        // handle the error here
        console.log(error);
      });
    }
  }, [page]);
  const addIdToSelectedList = (id) => {
    if (selectedUsersList.includes(id)) {
      setSelectedUsersList(
        selectedUsersList.filter((selectedId) => selectedId !== id)
      );
    } else {
      setSelectedUsersList([...selectedUsersList, id]);
    }
  };
  const toggleSelectAll = () => {
    if (selectedUsersList.length === userDataToShow?.data?.results?.length) {
      setSelectedUsersList([]);
    } else {
      setSelectedUsersList(
        userDataToShow?.data?.results?.map((result) => result._id) || []
      );
    }
  };
  const downloadSelectedUsers = async () => {
    const response = await api.game.getGameResultsCSV(selectedUsersList);
    FileDownload(response.data.csv, "report.csv");
  };
  return (
    <AppLayout>
      <Container fluid>
        <Spacer bottom={35}>
          <H1>Game Results</H1>
        </Spacer>
        <Row>
          <Col sm={8}>
            {showLoading ? (
              <Loading />
            ) : (
              <Table cellSpacing="0" cellPadding="0" loading={showLoading}>
                <tr>
                  <th align="left">
                    <Checkbox
                      checked={
                        selectedUsersList.length ===
                        userDataToShow?.data?.results?.length
                      }
                      indeterminate={
                        selectedUsersList.length > 0 &&
                        selectedUsersList.length <
                          userDataToShow?.data?.results?.length
                      }
                      onChange={toggleSelectAll}
                    />
                  </th>
                  <th
                    align="left"
                    onClick={() => {
                      callFilterApI("name");
                    }}
                  >
                    <span className="cursor-pointer">
                      Name{" "}
                      {showFilterDataFlag && filterTypeActive === "name"
                        ? showFilterIcon("name")
                        : ""}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      callFilterApI("email");
                    }}
                  >
                    <span className="cursor-pointer">
                      Email{" "}
                      {showFilterDataFlag && filterTypeActive === "email"
                        ? showFilterIcon("email")
                        : ""}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      callFilterApI("answer");
                    }}
                  >
                    <span className="cursor-pointer">
                      Answers{" "}
                      {showFilterDataFlag && filterTypeActive === "answer"
                        ? showFilterIcon("answer")
                        : ""}
                    </span>
                  </th>
                  <th>Video</th>
                  <th
                    onClick={() => {
                      showFilterData("updatedAt");
                    }}
                  >
                    <span className="cursor-pointer">
                      Date{" "}
                      {showFilterDataFlag && filterTypeActive === "updatedAt"
                        ? showFilterIcon("updatedAt")
                        : ""}
                    </span>
                  </th>
                  <th align="right">Actions</th>
                </tr>
                <tbody>
                  {userDataToShow &&
                    userDataToShow.data.results.map((result) => (
                      <tr key={result?._id}>
                        <td>
                          <Checkbox
                            checked={selectedUsersList.includes(result._id)}
                            onClick={() => {
                              addIdToSelectedList(result._id);
                            }}
                            className="pt-10"
                          />
                        </td>
                        <td>{formatUserName(result.userId)}</td>
                        <td align="center">{result.userId?.email}</td>
                        <td align="center">
                          {Object.keys(result.results).length}
                        </td>
                        {/* eslint-disable-next-line @typescript-eslint/quotes */}
                        <td align="center">{result.videoUrl ? "✅" : "❌"}</td>
                        <td align="center">
                          {moment(result.updatedAt).format("MM/DD/YYYY")}
                        </td>
                        <td align="right">
                          <ButtonComponent
                            onClick={() => navigateUserResults(result._id)}
                            link
                            small
                          >
                            View
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
            <FooterContainer>
              <PaginationContainer>
                <ButtonsContainer>
                  <ButtonComponent
                    disabled={page === 1}
                    onClick={() => {
                      setPage(page - 1);
                      setIsPaginationClicked(true);
                    }}
                    link
                  >
                    ⪻
                  </ButtonComponent>
                  <ButtonComponent
                    disabled={page === userDataToShow?.data.pages}
                    onClick={() => {
                      setPage(page + 1);
                      setIsPaginationClicked(true);
                    }}
                    link
                  >
                    ⪼
                  </ButtonComponent>
                </ButtonsContainer>
                <P>
                  {(page - 1) * PAGE_SIZE + 1} -{" "}
                  {page === userDataToShow?.data?.pages
                    ? userDataToShow?.data?.count
                    : page * PAGE_SIZE}{" "}
                  of {userDataToShow?.data?.count}
                </P>
              </PaginationContainer>
              <ExportInfo>
                <P>Export as CSV</P>
                {selectedUsersList?.length === 0 && (
                  <>
                    <ButtonComponent
                      link
                      small
                      onClick={() => downloadFile(page)}
                    >
                      This page
                    </ButtonComponent>
                    <ButtonComponent link small onClick={() => downloadFile()}>
                      All data
                    </ButtonComponent>
                  </>
                )}

                {selectedUsersList.length > 0 && (
                  <ButtonComponent
                    link
                    small
                    onClick={() => downloadSelectedUsers()}
                  >
                    Only selected users
                  </ButtonComponent>
                )}
              </ExportInfo>
            </FooterContainer>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default GameResults;
