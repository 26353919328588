import { AxiosPromise } from 'axios';
import client from '../core';

const getGameResults = (page: number): AxiosPromise<any> => client.get('game/results', { params: { page } });

const getUserGameResults = (id: string): AxiosPromise<any> => client.get(`game/results/${id}`);

const getGameResultsCSV = (selectedIds?: string[], page?: number, filterType? : string, sortOrder? : string ): AxiosPromise<any> => client.post('game/results/csv', {selectedIds}, { params: { page, filterType, sortOrder } });

const getFilterGamesResult = (page: number, filterType : string, sortOrder : string): AxiosPromise<any> => client.get('game/filter', { params: { page, filterType, sortOrder } });

// const getSelectedUserCSV = (selectedIds: string[]): AxiosPromise<any> => client.post('game/selectedEmailDownload/csv', { selectedIds });

export default {
  getGameResults,
  getUserGameResults,
  getGameResultsCSV,
  getFilterGamesResult,
  // getSelectedUserCSV,
};
