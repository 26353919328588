import React from 'react';
import { useHistory } from 'react-router';
import ButtonComponent from '../Button';
import Spacer from '../Spacer';

interface Props {
  path: string;
}

const BackButton: React.FunctionComponent<Props> = ({ path }) => {
  const history = useHistory();

  return (
    <Spacer bottom={25}>
      <ButtonComponent small outlined onClick={() => history.push(path)}>
        Back
      </ButtonComponent>
    </Spacer>
  );
};

export default BackButton;
