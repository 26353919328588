import React from 'react';
import styled from 'styled-components';
import SyncLoader from 'react-spinners/SyncLoader';

import { colors } from '../../config/styles';

interface Props {
  link?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  small?: boolean;
  secondary?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Button = styled.button<Props>`
  transition:
    background-color .3s ease-in-out,
    border-color .3s ease-in-out,
    transform .1s,
    color .3s,
    opacity .3s;
  height: ${({ small, link }) => (
    (link && 'auth')
    || (small && '44px')
    || '84px'
  )};
  width: ${({ small, link }) => (
    (link && 'auth')
    || (small && '180px')
    || '100%'
  )};
  background-color: ${({ outlined, secondary, link }) => (
    (secondary && !outlined && colors.placeholder)
    || (outlined && colors.white)
    || (link && 'transparent')
    || colors.mainGreen
  )};
  border-radius: 42px;
  border:
    ${({ small }) => (small ? '1px' : '3px')}
    solid
    ${({ secondary }) => (secondary ? colors.placeholder : colors.mainGreen)};
  ${({ link }) => (link ? 'border: none;' : '')}
  font-size: ${({ link, small }) => (link && small ? '16px' : '20px')}};
  font-family: Roboto;
  font-weight: 500;
  
  ${({ disabled }) => (disabled ? `
    opacity: 0.1;
    pointer-events: none;
  ` : '')}

  color: ${({ outlined, secondary, link }) => (
    (secondary && colors.text)
    || (link && colors.mainGreen)
    || (!outlined && colors.white)
    || colors.mainGreen
  )};
  
  &:hover {
    cursor: pointer;
    background-color: ${({ link }) => (link ? 'transparent' : colors.mainGreenDark)};
    border-color: ${colors.mainGreenDark};
    color: ${({ link }) => (link ? colors.mainGreenDark : colors.white)};
  }
  
  &:active {
    transform: scale(0.99);
  }
`;

const ButtonComponent: React.FunctionComponent<Props> = ({ loading, children, ...rest }) => (
  <Button {...rest}>
    {
      loading
        ? <SyncLoader size={10} color={colors.white} />
        : children
    }
  </Button>
);

export default ButtonComponent;
