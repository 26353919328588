import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/icons/logo.svg';

const HeaderContainer = styled.div`
  width: calc(100vw - 120px);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
`;

const Logo = styled.img``;

const Header: React.FunctionComponent = () => (
  <HeaderContainer>
    <Logo src={logo} alt="Nijineuro" />
  </HeaderContainer>
);

export default Header;
