import React from "react";
import { useQuery } from "react-query";
import { Container } from "react-grid-system";
import styled from "styled-components";
import { useParams } from "react-router";
import AppLayout from "../../components/AppLayout";
import { H1, P } from "../../components/Typography";
import api from "../../api";
import Spacer from "../../components/Spacer";
import { colors } from "../../config/styles";
import BackButton from "../../components/BackButton";
import moment from "moment";

const Table = styled.table`
  width: 100%;
  max-width: 800px;

  th {
    padding-bottom: 10px;
  }

  tr {
    th:first-child,
    td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  tr:not(:first-child) {
    border-radius: 5px;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const UserQuizResults: React.FunctionComponent = () => {
  const params = useParams<{ userId: string }>();
  const { data: response } = useQuery(
    [params.userId],
    api.quiz.getUserQuizResults
  );

  const user = response?.data?.userId;
  const responses = response?.data?.results;

  const handleBooleanResponse = (answer: boolean) => (answer ? "Yes" : "No");

  return (
    <AppLayout>
      <Container fluid>
        <BackButton path="/app/results" />
        <Spacer bottom={35}>
          <H1>Results for {user?.email}</H1>
          <P>{moment(response?.data.createdAt as string).format("LLLL")}</P>
        </Spacer>
        <Table cellSpacing="0" cellPadding="0">
          <tr>
            <th align="left">#</th>
            <th align="left">Question Name</th>
            <th align="center">Answer</th>
          </tr>
          {responses &&
            Object.keys(responses).map((name, index) => (
              <tr>
                <td>{index + 1}</td>
                <td align="left">{name}</td>
                <td align="center">
                  {typeof responses[name] === "boolean"
                    ? handleBooleanResponse(responses[name] as boolean)
                    : responses[name]}
                </td>
              </tr>
            ))}
        </Table>
      </Container>
    </AppLayout>
  );
};

export default UserQuizResults;
