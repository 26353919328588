import React, { FormEvent, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Spacer from '../../components/Spacer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { H1 } from '../../components/Typography';
import api from '../../api';
import { SetPasswordData } from '../../api/auth';
import useErrors from '../../utils/useErrors';
import { ErrorResponse, Response } from '../../typings/response';

import passwordIcon from '../../assets/icons/password.svg';
import Header from '../../components/Header';
import Page from '../../components/Page';
import AuthActions from '../../redux/auth/actions';

const Main: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const params = useParams<{ token: string }>();
  const [mutate, { error, status }] = useMutation<
  Response<{ token: string }>,
  SetPasswordData,
  ErrorResponse
  >(api.auth.setPassword);

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const errors = useErrors(error?.response);

  const onButtonClick = async () => {
    try {
      const response = await mutate({
        token: params.token,
        password,
        passwordRepeat,
      });

      dispatch(AuthActions.authorizeUser(response.data.token));
    } catch (err) {
      console.warn(err);
    }
  };

  const handleInputChange = (
    setter: (value: string) => void,
  ) => (e: FormEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
  };

  const buttonDisabled = !password || !passwordRepeat;

  return (
    <Page>
      <Header />
      <Container>
        <Row>
          <Col sm={6} offset={{ sm: 3 }}>
            <H1 align="center">Create New Password</H1>
            <Spacer top={35}>
              <Input
                type="password"
                error={errors.password}
                placeholder="Password"
                onChange={handleInputChange(setPassword)}
                icon={passwordIcon}
              />
            </Spacer>
            <Spacer top={35}>
              <Input
                type="password"
                error={errors.passwordRepeat}
                placeholder="Repeat Password"
                onChange={handleInputChange(setPasswordRepeat)}
                icon={passwordIcon}
              />
            </Spacer>
            <Spacer top={45} bottom={45}>
              <Row>
                <Col sm={8} offset={{ sm: 2 }}>
                  <Button
                    loading={status === 'loading'}
                    disabled={buttonDisabled}
                    onClick={onButtonClick}
                  >
                    Sign In
                  </Button>
                </Col>
              </Row>
            </Spacer>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default Main;
