import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { colors } from '../../config/styles';
import usePrevious from '../../utils/usePrevious';
import { InputVariants } from '../../typings/questions';
import Select from '../Select';
import UnitInput from '../UnitInput';

interface BaseProps {
  type?: InputVariants | string;
  onChange: (event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | string) => void;
  value?: string;
  error?: string;
  icon?: string;
  children?: string[];
  innerRef?: any;
  small?: boolean;
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input<BaseProps>`
  transition: .1s ease-in-out;
  border: 1px solid ${({ error }): string => (error ? colors.error : colors.border)};
  height: ${({ small }) => (small ? '50px' : '84px')};
  width: calc(100% - 50px);
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  color: ${colors.text};
  font-size: 17px;
  padding: 0 25px;
  
  ${({ icon }) => (icon ? 'padding-left: 60px;' : '')}
  
  &:focus {
    border-color: ${colors.mainGreen};
  }
  
  &::placeholder {
    color: ${colors.placeholder}
  }
`;

const InputError = styled.p`
  color: ${colors.error};
  text-transform: capitalize;
  font-size: 12px;
  margin-top: 5px;
`;

const InputImage = styled.img`
  position: absolute;
  left: 30px;
  transform: translateX(-50%);
`;

const Input: React.FunctionComponent<BaseProps & React.HTMLProps<HTMLInputElement>> = (props) => {
  const {
    error, placeholder, onChange, value, type, icon, innerRef, small,
  } = props;

  const prevError = usePrevious(error);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  useEffect(() => {
    if (error && !prevError) {
      setShouldDisplayError(true);
    }
  }, [error, prevError, setShouldDisplayError]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setShouldDisplayError(false);
    onChange(e);
  };

  return (
    <InputContainer>
      <InputWrapper>
        {icon && <InputImage src={icon} alt={placeholder} />}
        <StyledInput
          error={shouldDisplayError ? error : ''}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          type={type}
          icon={icon}
          ref={innerRef}
          small={small}
        />
      </InputWrapper>
      {shouldDisplayError && <InputError>{error}</InputError>}
    </InputContainer>
  );
};

const dateInputMask = (rawValue) => {
  const mask: Array<string | RegExp> = [/[0-1]/];

  if (rawValue[0] === '0') {
    mask.push(/[1-9]/);
  } else {
    mask.push(/[0-2]/);
  }

  mask.push('/');
  mask.push(/[0-3]/);

  if (rawValue[3] && rawValue[3] === '3') {
    mask.push(/[0-1]/);
  } else if (rawValue[3] && rawValue[3] === '0') {
    mask.push(/[1-9]/);
  } else {
    mask.push(/\d/);
  }

  mask.push('/');
  mask.push(/\d/);
  mask.push(/\d/);
  mask.push(/\d/);
  mask.push(/\d/);

  return mask;
};

const InputManager: React.FunctionComponent<BaseProps & React.HTMLProps<HTMLInputElement>> = ({
  type,
  ...rest
}) => {
  switch (type) {
    case InputVariants.DATE:
      return (
        <MaskedInput
          mask={dateInputMask}
          placeholder={rest.placeholder}
          value={rest.value}
          onChange={rest.onChange}
          guide={false}
          render={(ref, props) => (
            <Input innerRef={ref} {...props} />
          )}
        />
      );
    case InputVariants.HEIGHT:
      return (
        <UnitInput
          {...rest}
          units={[
            { label: 'FT', mask: [/\d/, '’', /\d/, '’’'], validLength: 5 },
            { label: 'CM', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
          ]}
        />
      );
    case InputVariants.SELECT:
      return <Select {...rest} />;
    case InputVariants.TEXT:
      return <Input {...rest} type={type} />;
    case InputVariants.WEIGHT:
      return (
        <UnitInput
          {...rest}
          units={[
            { label: 'LBS', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
            { label: 'KG', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
          ]}
        />
      );
    default:
      return <Input {...rest} type={type} />;
  }
};

export default InputManager;
