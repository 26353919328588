export const colors = {
  mainGreen: '#82D8A5',
  mainGreenDark: '#67cf92',
  border: '#D5D7DB',
  text: '#3A3B41',
  error: '#E97C75',
  placeholder: '#8890BB',
  background: '#2A2069',
  backgroundLight: '#f7f7fc',
  white: '#FFFFFF',
};

export const fonts = {

};
