import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer, { State as AuthState } from './auth/reducer';
import userReducer, { State as UserState } from './user/reducer';

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, applyMiddleware(logger));
export const persistor = persistStore(store);

export interface RootState {
  auth: AuthState;
  user: UserState;
}
