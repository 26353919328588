import auth from './auth';
import quiz from './quiz';
import game from './game';
import user from './user';

export default {
  auth,
  quiz,
  game,
  user
};
