import { AxiosPromise } from 'axios';
import client from '../core';
import { Question } from '../../typings/questions';
import { User, UserFeedback } from '../../typings/api';

interface ExtendedQuestion {
  _id: string;
}

// const getQuizQuestions = (): AxiosPromise<(Question & ExtendedQuestion)[]> => client.get('quiz');

// const getQuizQuestion = (id: string): AxiosPromise<Question> => client.get(`quiz/${id}`);

interface GetResultsResponse {
  count: number;
  page: number;
  pages: number;
  results: User[];
}
interface GetUserFeedbackResponse {
  count: number;
  page: number;
  pages: number;
  status: boolean;
  code: number;
  data: UserFeedback[];
}

const getUsers = (page: number): AxiosPromise<GetResultsResponse> => client.get('users/all', { params: { page } });
const getFilterusers = (page : number, filterType : string, sortOrder : string): AxiosPromise<GetResultsResponse> => client.get('users/filter', { params: { page, filterType, sortOrder } });
// const getQuizResults = (page: number): AxiosPromise<GetResultsResponse> => client.get('quiz/results', { params: { page } });

// const createQuizQuestion = (data: Question): AxiosPromise<Question> => client.post('quiz', data);

// const saveQuizOrder = (order: string[]): AxiosPromise<Question> => client.post('quiz/order', { order });

// const deleteQuizQuestion = (id: string): AxiosPromise<Question> => client.delete(`quiz/${id}`);

// const saveQuestion = (id: string, data): AxiosPromise<Question> => client.put(`quiz/${id}`, data);

const getUserDetails = (id: string): AxiosPromise<any> => client.get(`users/${id}`);
const activateUserById = (id: string): AxiosPromise<any> => client.put(`users/activate/${id}`);
const deactivateUserById = (id: string): AxiosPromise<any> => client.put(`users/deactivate/${id}`);
const resetUserFormById = (id: string): AxiosPromise<any> => client.put(`users/form-reset/${id}`);
const resetUserGameById = (id: string): AxiosPromise<any> => client.put(`users/form-all-game-result-of-user/${id}`);
const resentUserVerificatoinLinkById = (id: string): AxiosPromise<any> => client.put(`users/resent-user-verification-link/${id}`);
const deleteUserById = (id: string): AxiosPromise<any> => client.delete(`users/delete/${id}`);
const getUserFeedback = (page: number): AxiosPromise<GetUserFeedbackResponse> => client.get('userfeedback', { params: { page } });
const getUserFeedbackCSV = (page?: number): AxiosPromise<any> => client.get('userfeedback/csv', { params: { page } });
const getSelectedUserCSV = (selectedIds: string[]): AxiosPromise<any> => client.post('users/csv', { selectedIds });
// const getResultsCSV = (page?: number): AxiosPromise<any> => client.get('quiz/results/csv', { params: { page } });
const getUserResultsCSV = (selectedIds?: string[], page?: number, filterType? : string, sortOrder? : string): AxiosPromise<any> => client.post('users/results/csv', {selectedIds}, { params: { page, filterType, sortOrder } });

export default {
//   getQuizQuestions,
  getUsers,
  getFilterusers,
  getUserDetails,
  activateUserById,
  deactivateUserById,
  resetUserFormById,
  resetUserGameById,
  resentUserVerificatoinLinkById,
  getUserFeedback,
  getUserFeedbackCSV,
  deleteUserById,
  getSelectedUserCSV,
  getUserResultsCSV
//   getUserQuizResults,
//   getResultsCSV,
//   createQuizQuestion,
//   deleteQuizQuestion,
//   getQuizQuestion,
//   saveQuestion,
//   saveQuizOrder,
};
