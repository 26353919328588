import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
  return (
    <div style={{"position":"absolute","height":"100%","width":"100%","margin":"0 auto","display":"flex","justifyContent":"center","alignItems":"center"}}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>
    
  );
}
