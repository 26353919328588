import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../config/styles';

import spinner from '../../assets/icons/spinner.svg';

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Image = styled.img`
  animation-name: ${spinning};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Loader: React.FunctionComponent = () => (
  <Page>
    <Image src={spinner} alt="Spinner" />
  </Page>
);

export default Loader;
