import React from 'react';
import { usePaginatedQuery } from 'react-query';
import moment from 'moment';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import FileDownload from 'js-file-download';
import AppLayout from '../../components/AppLayout';
import { H1, P } from '../../components/Typography';
import api from '../../api';
import Spacer from '../../components/Spacer';
import ButtonComponent from '../../components/Button';
import { colors } from '../../config/styles';
import { formatUserName } from '../../utils/formatUserName';
import Loading from '../../components/Loading';
const Table = styled.table<{ loading: boolean }>`
  transition: opacity .3s ease-in-out;
  width: 100%;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  
  th {
    padding-bottom: 10px;
  }
  
  tr {
    th:first-child, td:first-child {
      padding-left: 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    
    th:last-child, td:last-child {
      padding-right: 10px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  
  tbody tr {
    border-radius: 5px;
  
    td {
      padding: 10px;
    }
    
    &:nth-child(odd) td {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
 
  * + * {
    margin-left: 15px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  
    
  ${ButtonsContainer} {
    margin-right: 15px;
  }
`;

const ExportInfo = styled.div`
  display: flex;
  align-items: center;
  
  * + * {
    margin-left: 10px;
  }
`;

const FooterContainer = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PAGE_SIZE = 25;

const GameResults: React.FunctionComponent = () => {
  const history = useHistory();
  const [page, setPage] = React.useState(1);

  const fetchResults = (key, passedPage = 1) => api.user.getUserFeedback(passedPage);

  const {
    resolvedData,
    isFetching,
  } = usePaginatedQuery(['user-feedback', page], fetchResults);

  const downloadFile = async (queryPage?: number) => {
    const response = await api.user.getUserFeedbackCSV(queryPage);
    FileDownload(response.data.csv, 'report.csv');
  };

  const navigateUserResults = (userId: string) => {
    history.push(`/app/user-feedback/${userId}`);
  };

  return (
    <AppLayout>
      <Container fluid>
        <Spacer bottom={35}>
          <H1>User Feedback</H1>
        </Spacer>
        <Row>
          <Col sm={8}>
          {(isFetching)?<Loading />:(
            <Table cellSpacing="0" cellPadding="0" loading={isFetching}>
              <tr>
                <th align="left">Email</th>
                <th>Date</th>
                <th>URL</th>
                <th>Module</th>
                <th>Comment</th>
                
              </tr>
              <tbody>
                {resolvedData && resolvedData.data.data.map((result) => (
                  <tr key={result?._id}>
                    <td>{((result.useremail == null)?result.userId:result.useremail)}</td>
                    <td align="center">{moment(result.createdAt).format('MM/DD/YYYY')}</td>
                    <td>{result.url}</td>
                    <td>{result.module}</td>
                    <td>{result.comment}</td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
            <FooterContainer>
              <PaginationContainer>
                <ButtonsContainer>
                  <ButtonComponent
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                    link
                  >
                    ⪻
                  </ButtonComponent>
                  <ButtonComponent
                    disabled={page === resolvedData?.data.pages}
                    onClick={() => setPage(page + 1)}
                    link
                  >
                    ⪼
                  </ButtonComponent>
                </ButtonsContainer>
                <P>
                  {(page - 1) * PAGE_SIZE + 1}
                  {' '}
                  -
                  {' '}
                  {page === resolvedData?.data?.pages
                    ? resolvedData?.data?.count
                    : page * PAGE_SIZE}
                  {' '}
                  of
                  {' '}
                  {resolvedData?.data?.count}
                </P>
              </PaginationContainer>
              <ExportInfo>
                <P>Export as CSV</P>
                <ButtonComponent
                  link
                  small
                  onClick={() => downloadFile(resolvedData?.data?.page)}
                >
                  This page
                </ButtonComponent>
                <ButtonComponent
                  link
                  small
                  onClick={() => downloadFile()}
                >
                  All data
                </ButtonComponent>
              </ExportInfo>
            </FooterContainer>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
};

export default GameResults;
