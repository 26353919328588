import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { colors } from '../../config/styles';
import selectArrow from '../../assets/icons/select-arrows.svg';

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 35px;
  transform: translateX(50%);
`;

const StyledSelect = styled.select<{ withPlaceholder: boolean, small?: boolean }>`
  transition: .1s ease-in-out;
  border: 1px solid ${colors.border};
  height: ${({ small }) => (small ? '52px' : '82px')};
  width: 100%;
  border-radius: 6px;
  color: ${({ withPlaceholder }) => (withPlaceholder ? colors.placeholder : colors.text)};
  font: 400 17px Roboto;
  padding: 0 70px 0 25px;

  &:focus {
    border-color: ${colors.mainGreen};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

interface Props {
  value?: string;
  onChange: (value: ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  children?: string[];
  options?: { value: string, label: string }[];
  small?: boolean;
}

const Select: React.FunctionComponent<Props> = ({
  value = '', onChange, placeholder, options, children, small,
}) => {
  let variants = options;

  if (!variants) {
    variants = children.map((c) => ({ label: c, value: c }));
  }

  return (
    <SelectContainer>
      <StyledSelect small={small} withPlaceholder={value === ''} value={value} onChange={onChange}>
        <option value="" disabled hidden style={{ color: colors.placeholder }}>{placeholder}</option>
        {variants.map((child) => (
          <option
            key={child.value}
            value={child.value}
          >
            {child.label}
          </option>
        ))}
      </StyledSelect>
      <ArrowIcon src={selectArrow} alt="Select" />
    </SelectContainer>
  );
};

export default Select;
