import React from "react";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../src/styles/global.css";
import FontsInject from "./components/FontsInject";
import configureGrid from "./utils/grid";

import Routes from "./components/Routes";
import { store, persistor } from "./redux";

configureGrid();

const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto' !important;
  }
`;

const App: React.FunctionComponent = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FontsInject />
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
