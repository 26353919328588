import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import SignIn from '../../container/SignIn';
import ResetPassword from '../../container/ResetPassword';
import SetPassword from '../../container/SetPassword';
import { RootState } from '../../redux';
import QuizQuestions from '../../container/QuizQuestions';
import Users from '../../container/Users';
import QuizResults from '../../container/QuizResults';
import api from '../../api';
import Loader from '../../container/Loader';
import UserActions from '../../redux/user/actions';
import UserQuizResults from '../../container/QuizResult';
import UserDetails from '../../container/UserDetails';
import GameResults from '../../container/GameResults';
import UserFeedback from '../../container/UserFeedback';
import GameResult from '../../container/GameResult';

const AuthContext = React.createContext(false);

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthContext.Consumer>
        {(isAuthenticated) => (isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        ))}
      </AuthContext.Consumer>
    )}
  />
);

// eslint-disable-next-line @typescript-eslint/naming-convention
const UnauthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthContext.Consumer>
        {(isAuthenticated) => (!isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/app/users" />
        ))}
      </AuthContext.Consumer>
    )}
  />
);

const Routes: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  const isAuthenticated = Boolean(auth.token && auth.expiration > Date.now());

  const profile = useQuery('profile', api.auth.getProfile, { manual: true });

  useEffect(() => {
    const load = async () => {
      try {
        const profileResponse = await profile.refetch();
        dispatch(UserActions.saveUser(profileResponse.data));
      } catch (error) {
        console.warn(error);
      }
    };

    if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isAuthenticated && (profile.isFetching || !profile.data)) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={isAuthenticated}>
      <Switch>
        <UnauthRoute exact path="/" component={SignIn} />
        <UnauthRoute exact path="/reset" component={ResetPassword} />
        <UnauthRoute exact path="/reset/:token" component={SetPassword} />
        <AuthRoute exact path="/app/users" component={Users} />
        <AuthRoute exact path="/app/users/:userId" component={UserDetails} />
        <AuthRoute exact path="/app/questions" component={QuizQuestions} />
        <AuthRoute exact path="/app/results" component={QuizResults} />
        <AuthRoute exact path="/app/results/:userId" component={UserQuizResults} />
        <AuthRoute exact path="/app/game-results" component={GameResults} />
        <AuthRoute exact path="/app/game-results/:gameId" component={GameResult} />
        <AuthRoute exact path="/app/user-feedback" component={UserFeedback} />
        <Redirect to="/" />
      </Switch>
    </AuthContext.Provider>
  );
};

export default Routes;
