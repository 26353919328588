import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';


import { P } from '../../components/Typography';
import Spacer from '../../components/Spacer';
import ButtonComponent from '../../components/Button';
import close from '../../assets/icons/close.svg';
import { colors } from '../../config/styles';

const RemoveButton = styled.button`
   cursor: pointer;
   background: none;
   border: none;
   margin-left: 15px;
   
   img {
    width: 14px;
    height: 14px;
   }
`;

const QuestionsButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionContainer = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  background: white;
  position: relative;
  transition: box-shadow .2s ease-in-out;
  height: 50px;
  border-radius: 15px;
  border: 1px solid ${colors.backgroundLight};
  display: flex;
  justify-content: space-between;
  
  &:hover {
    box-shadow: 0 3px 30px 0px rgba(0,0,0,.05);
  }
`;

const QuestionBody = styled.div`
  display: flex;
  align-items: center;
`;

const Question = (props) => {
  const {
    question, openModalWithId, removeQuizQuestion, index,
  } = props;

  return (
    <Draggable key={question._id} draggableId={question._id} index={index}>
      {(draggableProvided) => (
        <QuestionContainer
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
        >
          <QuestionBody>
            <P>
              {question.title}
            </P>
            {question.subtitle && (
              <Spacer left={15}>
                <P colorKey="placeholder" size={14}>{question.subtitle}</P>
              </Spacer>
            )}
          </QuestionBody>
          <QuestionsButtonsContainer>
            <ButtonComponent
              link
              small
              onClick={() => openModalWithId(question._id)}
            >
              Edit
            </ButtonComponent>
            <RemoveButton onClick={() => removeQuizQuestion(question._id)}>
              <img src={close} alt="Remove" />
            </RemoveButton>
          </QuestionsButtonsContainer>
        </QuestionContainer>
      )}
    </Draggable>
  );
};

export default Question;
