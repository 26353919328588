import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import Modal from '../../components/Modal';
import Select from '../../components/Select';
import {
  CustomInput, InputVariants, Question, QuestionVariants,
} from '../../typings/questions';
import InputManager from '../../components/Input';
import Spacer from '../../components/Spacer';
import ButtonComponent from '../../components/Button';
import { P } from '../../components/Typography';
import remove from '../../assets/icons/close.svg';
import api from '../../api';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import './style.css';
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const VariantContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    width: 100%;
  }
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  max-width: 15px;
  margin-left: 15px;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

const CustomPayload = styled(Spacer)`
  display: flex;
  
  & > ${Spacer}:not(:last-child) {
    flex-grow: 1;
  }
`;

const CustomPayloadFieldChildren = styled(Spacer)`
  width: 50%;
  margin-left: auto;
  display: flex;
  align-items: center;
  
  *:first-child {
    width: 100%;
  }
`;

interface Props {
  visible: boolean;
  close: () => void;
  onCreate: () => void;
  questionId: string;
}

const types = [
  { value: QuestionVariants.CUSTOM, label: 'Custom' },
  { value: QuestionVariants.YES_NO, label: 'Yes / No' },
  { value: QuestionVariants.SINGLE_VARIANT, label: 'Single variant' },
  { value: QuestionVariants.MULTIPLE_CHOICE, label: 'Multiple Choice' },
];

const inputTypes = [
  { value: InputVariants.DATE, label: 'Date' },
  { value: InputVariants.HEIGHT, label: 'Height' },
  { value: InputVariants.WEIGHT, label: 'Weight' },
  { value: InputVariants.SELECT, label: 'Select' },
  { value: InputVariants.TEXT, label: 'Text' },
];

const CreateNewModal: React.FunctionComponent<Props> = ({
  visible, close, onCreate, questionId,
}) => {
  const {
    refetch: fetchQuestion,
  } = useQuery([questionId], api.quiz.getQuizQuestion, { manual: true });

  const [questionType, setQuestionType] = useState('');
  const [conditionForm, setConditionForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isnoneenable, setIsNoneEnable] = useState(false);
  const [nonedisplaytext, setnoneDisplayText] = useState('');

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [name, setName] = useState('');

  const [payload, setPayload] = useState(['']);
  const [customPayload, setCustomPayload] = useState<CustomInput[]>([{ type: InputVariants.TEXT, name: '', placeholder: '' }]);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await fetchQuestion();

        setQuestionType(response.data.type);
        setTitle(response.data.title);
        setSubtitle(response.data.subtitle);
        
        // setnoneDisplayText(response.data.nonedisplaytext);
        // setIsNoneEnable(response.data.isnoneenable)
        if ('name' in response.data) {
          setName(response.data.name);
        }
        if ('nonedisplaytext' in response.data) {
          setnoneDisplayText(response.data.nonedisplaytext);
        }
        if ('isnoneenable' in response.data) {
          setIsNoneEnable(response.data.isnoneenable);
        }

        if ('payload' in response.data && (response.data.type === QuestionVariants.SINGLE_VARIANT || response.data.type === QuestionVariants.MULTIPLE_CHOICE)  ) {
          setPayload(response.data.payload);
        }

        if ('payload' in response.data && response.data.type === QuestionVariants.CUSTOM) {
          setCustomPayload(response.data.payload);
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (visible && questionId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      load();
    }
  }, [visible, questionId, fetchQuestion]);

  const addMoreVariantField = () => {
    setPayload((prev) => ([...prev, '']));
  };

  const removeVariant = (index: number) => {
    const copy = [...payload];
    copy.splice(index, 1);

    setPayload(copy);
  };

  const handlePayloadChange = (index: number, value: string) => {
    const copy = [...payload];
    copy[index] = value;

    setPayload(copy);
  };
  const setConditionFormArray = (index: number, value: boolean) => {
    const copy = [...conditionForm];
    copy[index] = value;
    setConditionForm(copy);
  };
  const addingNoneValue = (status :boolean ) => {
    setIsNoneEnable(status);
  };

  const addMoreCustomPayloadField = () => {
    setCustomPayload([...customPayload, { type: InputVariants.TEXT, name: '', placeholder: '' }]);
  };

  const removeCustomPayloadField = (index: number) => {
    const copy = [...customPayload];
    copy.splice(index, 1);

    setCustomPayload(copy);
  };

  const handleCustomPayloadNameChange = (index: number, value: string) => {
    const copy = [...customPayload];
    copy[index].name = value;

    setCustomPayload(copy);
  };

  const handleCustomPayloadPlaceholderChange = (index: number, value: string) => {
    const copy = [...customPayload];
    copy[index].placeholder = value;

    setCustomPayload(copy);
  };
  const handlelimitChange = (index: number, value: string, type : string) => {
    const copy = [...customPayload];
    copy[index][type] = value;

    setCustomPayload(copy);
  };

  const handleCustomPayloadTypeChange = (index: number, value: InputVariants) => {
    const copy = [...customPayload];
    copy[index].type = value;

    if (value === InputVariants.SELECT) {
      // @ts-ignore
      copy[index].children = [''];
    } else {
      // @ts-ignore
      copy[index].children = undefined;
    }
    
    setCustomPayload(copy);
  };

  const addCustomFieldSelectVariant = (index: number) => {
    const copy = [...customPayload];
    // @ts-ignore
    copy[index].children = [...copy[index].children, ''];

    setCustomPayload(copy);
  };

  const changeCustomFieldSelectVariant = (index: number, childIndex: number, value: string) => {
    const copy = [...customPayload];
    // @ts-ignore
    copy[index].children[childIndex] = value;
    console.log(copy);
    
    setCustomPayload(copy);
  };

  const removeCustomFieldSelectVariant = (index: number, childIndex: number) => {
    const copy = [...customPayload];
    // @ts-ignore
    copy[index].children.splice(childIndex, 1);

    setCustomPayload(copy);
  };

  const getButtonDisabled = (): boolean => {
    switch (questionType) {
      case QuestionVariants.CUSTOM:
        const hasCustomMeta = !!title;
        const correctFields = customPayload.every((p) => {
          if (p.type !== InputVariants.SELECT) {
            return p.name && p.placeholder;
          }
          return p.name && p.placeholder && p.children.every((c) => !!c);
        });

        return !(hasCustomMeta && correctFields);
      case QuestionVariants.SINGLE_VARIANT:
        const hasMeta = title && name;
        const correctVariants = payload.every((p) => !!p);

        return !(hasMeta && correctVariants);
      case QuestionVariants.MULTIPLE_CHOICE:
        const hasMetaMultiple = title && name;
        const correctVariantsMultiple = payload.every((p) => !!p);

        return !(hasMetaMultiple && correctVariantsMultiple);
      case QuestionVariants.YES_NO:
        return !(title && name);
      default:
        return true;
    }
  };

  const getQuestionData = (): Question => {
    switch (questionType) {
      case QuestionVariants.CUSTOM:
        return {
          type: QuestionVariants.CUSTOM,
          title,
          subtitle,
          payload: customPayload,
        };
      case QuestionVariants.SINGLE_VARIANT:
        return {
          type: QuestionVariants.SINGLE_VARIANT,
          title,
          subtitle,
          name,
          payload,
          isnoneenable,
          nonedisplaytext,
        };
      case QuestionVariants.MULTIPLE_CHOICE:
        return {
          type: QuestionVariants.MULTIPLE_CHOICE,
          title,
          subtitle,
          name,
          payload,
          isnoneenable,
          nonedisplaytext,
        };
      case QuestionVariants.YES_NO:
        return {
          type: QuestionVariants.YES_NO,
          title,
          subtitle,
          name,
        };
      default:
        return {} as Question;
    }
  };

  const resetModalState = () => {
    setQuestionType('');
    setTitle('');
    setSubtitle('');
    setName('');
    setPayload(['']);
    setIsNoneEnable(false);
    setnoneDisplayText("")
    setCustomPayload([{ type: InputVariants.TEXT, name: '', placeholder: '' }]);
  };

  const handleClose = () => {
    close();
    setTimeout(() => {
      resetModalState();
    }, 350);
  };

  const createQuestion = async () => {
    try {
      setLoading(true);
      await api.quiz.createQuizQuestion(getQuestionData());
      onCreate();
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const saveQuestion = async () => {
    try {
      setLoading(true);
      await api.quiz.saveQuestion(questionId, getQuestionData());
      onCreate();
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Modal visible={visible} closeModal={handleClose}>
      <Select
        value={questionType}
        onChange={(e) => setQuestionType(e.target.value)}
        options={types}
        placeholder="Question Type (required)"
        small
      />
      <Spacer bottom={20} />
      <InputManager small value={title} placeholder="Title (required)" onChange={(e) => setTitle(e.target.value)} />
      <Spacer bottom={20} />
      <InputManager
        small
        value={subtitle}
        placeholder="Subtitle"
        onChange={(e) => setSubtitle(e.target.value)}
      />
      {questionType !== QuestionVariants.CUSTOM && (
        <>
          <Spacer bottom={20} />
          <InputManager value={name} small placeholder="Name (required)" onChange={(e) => setName(e.target.value)} />
        </>
      )}
      {questionType === QuestionVariants.CUSTOM && (
        <>
          <Spacer bottom={40} />
          <P weight={500} size={18}>Inputs</P>
          {customPayload.map((customPayloadField, index) => (
            <>
              <CustomPayload top={20}>
                <Grid container spacing={2} >
                  <Grid item xs={4}>
                      <div className="textLable" >User Input Type </div>
                      <Select
                          small
                          onChange={(e) => handleCustomPayloadTypeChange(
                            index, e.target.value as InputVariants,
                          )}
                          value={customPayloadField.type}
                          options={inputTypes}
                        />
                  </Grid>
                  <Grid item xs={4}>
                      <Spacer right={20}>
                      <div className="textLable" >Input name</div>
                      <InputManager
                        value={customPayloadField.name}
                        small
                        placeholder="Input name (required)"
                        onChange={(e) => handleCustomPayloadNameChange(index, e.target.value)}
                      />
                    </Spacer>
                  </Grid> 
                  <Grid item xs={4}>
                      <Spacer right={20}>
                      <div className="textLable" >Input placeholder</div>
                      <InputManager
                        value={customPayloadField.placeholder}
                        small
                        placeholder="Input placeholder (required)"
                        onChange={(e) => handleCustomPayloadPlaceholderChange(index, e.target.value)}
                        maxLength={30}
                      />
                    </Spacer>
                  </Grid>
               </Grid> 
                
                
                
                {index !== 0 ? (
                  <RemoveButton onClick={() => removeCustomPayloadField(index)}>
                    <img src={remove} alt="Delete" />
                  </RemoveButton>
                )
                  : <Spacer left={30} />}
                
              </CustomPayload>
              {(customPayload[index]['type'] == InputVariants.TEXT) && (
              <div style={{ marginTop :"20px"}}>
              <div>
                  <Checkbox   color="success" 
                              checked ={((typeof(conditionForm[index]) !== 'undefined' && conditionForm[index]) || customPayloadField['commonlyAcceptedUpperLimit'] != "")?true:false}
                              onChange={(e)=>{setConditionFormArray(index,e.target.checked)}}

                  /> Set conditions for input field 
              </div>
              {((typeof(conditionForm[index]) !== 'undefined' && conditionForm[index]) || customPayloadField['commonlyAcceptedUpperLimit'] != "")?(<div>
                <Grid container spacing={2} >
                  <Grid item xs={4}>
                    <div className="textLable" >Set lower limit</div>
                    <TextField  id="lowerLimit" 
                                variant="outlined"
                                value={customPayloadField.lowerlimit}
                                onChange={(e) => handlelimitChange(index, e.target.value, "lowerlimit")} />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="textLable">Set upper limit</div>
                    <TextField id="upperLimit" 
                                variant="outlined"
                                value={customPayloadField.upperlimit}
                                onChange={(e) => handlelimitChange(index, e.target.value, "upperlimit")} />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="textLable">Error message</div>
                    <TextField id="errorMessage" 
                                variant="outlined" 
                                value={customPayloadField.errorMessage}
                                onChange={(e) => handlelimitChange(index, e.target.value, "errorMessage")}/>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className="textLable">Accepted lower limit value </div>

                    <TextField id="lowerLimit" 
                                variant="outlined"
                                value={customPayloadField.commonlyAcceptedLowerLimit}
                                onChange={(e) => handlelimitChange(index, e.target.value, "commonlyAcceptedLowerLimit")} />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="textLable">Accepted upper limit value </div>
                    <TextField id="upperLimit" 
                                variant="outlined"
                                value={customPayloadField.commonlyAcceptedUpperLimit}
                                onChange={(e) => handlelimitChange(index, e.target.value, "commonlyAcceptedUpperLimit")} />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="textLable">Confirm message</div>
                    <TextField id="confirmMessage" 
                                variant="outlined" 
                                value={customPayloadField.confirmationMessage}
                                onChange={(e) => handlelimitChange(index, e.target.value, "confirmationMessage")}/>
                  </Grid>
                </Grid>
              </div>):""}
              
              </div>)}
              {'children' in customPayloadField && customPayloadField.children && (
                <>
                  {customPayloadField.children.map((child, childIndex) => (
                    <CustomPayloadFieldChildren top={20}>
                      <InputManager
                        value={child}
                        small
                        placeholder="Select Variant (required)"
                        onChange={(e) => changeCustomFieldSelectVariant(
                          index, childIndex, e.target.value,
                        )}
                      />
                      {childIndex !== 0 ? (
                        <RemoveButton onClick={() => removeCustomFieldSelectVariant(
                          index, childIndex,
                        )}
                        >
                          <img src={remove} alt="Delete" />
                        </RemoveButton>
                      )
                        : <Spacer left={30} />}
                    </CustomPayloadFieldChildren>
                  ))}
                  <CustomPayloadFieldChildren top={20}>
                    <ButtonComponent
                      link
                      onClick={() => addCustomFieldSelectVariant(index)}
                    >
                      Add more variants +
                    </ButtonComponent>
                  </CustomPayloadFieldChildren>
                </>
              )}
              <hr style={{ margin :"2rem 0rem"}}/>
            </>
          ))}
          <Spacer bottom={20} />
          <ButtonComponent link onClick={addMoreCustomPayloadField}>Add more +</ButtonComponent>
        </>
      )}
      {(questionType === QuestionVariants.SINGLE_VARIANT || questionType === QuestionVariants.MULTIPLE_CHOICE) && (
        <>
          <Spacer bottom={40} />
          <P weight={500} size={18}>Variants</P>
          {payload.map((value, index) => (
            <>
              <Spacer bottom={20} />
              <VariantContainer>
                <InputManager
                  small
                  value={value}
                  placeholder={`Variant #${index + 1} (required)`}
                  onChange={(e) => handlePayloadChange(index, e.target.value)}
                  maxLength={40}
                />
                {index !== 0 ? (
                  <RemoveButton onClick={() => removeVariant(index)}>
                    <img src={remove} alt="Delete" />
                  </RemoveButton>
                )
                  : <Spacer left={30} />}
              </VariantContainer>
            </>
          ))}
          <div>
            <Spacer bottom={20} />
            <ButtonComponent link onClick={addMoreVariantField}>Add more +</ButtonComponent>
          </div>
          
          <div>
              <Grid container spacing={2} sx={{ margin : "0px"}}>
                <Grid item xs={3}>
                  <FormControlLabel
                    value="none"
                    control={<Checkbox checked={isnoneenable} 
                                        onChange={(e)=>{addingNoneValue(e.target.checked)}}
                                        color="success"
                                        />}
                    label="Enable None of the options"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={6} sx={{ display : "flex", flexDirection : "column"}}>
                  <b style={{ color : "#db1a1a"}}>Lable Name</b>
                  <TextField id="outlined-basic" 
                              value={nonedisplaytext} 
                              onChange={(e) => setnoneDisplayText(e.target.value)}
                              variant="outlined" />
                </Grid>
              </Grid>
          </div>
        </>
      )}
      <Spacer bottom={40} />
      <Footer>
        <ButtonComponent
          disabled={getButtonDisabled()}
          small
          onClick={questionId ? saveQuestion : createQuestion}
          loading={loading}
        >
          {questionId ? 'Save' : 'Create'}
        </ButtonComponent>
      </Footer>
    </Modal>
  );
};

export default CreateNewModal;
