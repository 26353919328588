import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Col, Container, Row } from 'react-grid-system';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import api from '../../api';
import AppLayout from '../../components/AppLayout';
import Spacer from '../../components/Spacer';
import { H1 } from '../../components/Typography';
import ButtonComponent from '../../components/Button';
import CreateNewModal from './CreateNewModal';
import Question from './Question';

const HeaderContainer = styled(Spacer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const QuestionsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuizQuestions: React.FunctionComponent = () => {
  const { data: response, refetch: refetchQuestions } = useQuery('questions', api.quiz.getQuizQuestions);

  const [order, setOrder] = useState([]);
  const [savingOrder, setSavingOrder] = useState(false);

  useEffect(() => {
    if (response?.data) {
      setOrder(response.data.map((q) => q._id));
    }
  }, [response]);

  const [addNewModalVisible, setAddNewModalVisible] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');

  const removeQuizQuestion = async (id: string) => {
    try {
      await api.quiz.deleteQuizQuestion(id);
      await refetchQuestions();
    } catch (err) {
      console.error(err);
    }
  };

  const openModalWithId = (id: string) => {
    setSelectedQuestionId(id);
    setAddNewModalVisible(true);
  };

  const closeModal = () => {
    setSelectedQuestionId('');
    setAddNewModalVisible(false);
  };

  const onDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newOrder = [...order];

    newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, draggableId);

    setOrder(newOrder);
  };

  const getShouldDisplaySaveOrderButton = (): boolean => {
    if (
      !order.length
      || !response?.data.length
      || (!order.length !== !response?.data.length)
    ) return false;

    for (let i = 0; i < order.length; i += 1) {
      if (
        order[i]
        && response?.data?.[i]?._id
        && order[i] !== response?.data?.[i]?._id) {
        return true;
      }
    }

    return false;
  };

  const saveNewOrder = async () => {
    try {
      setSavingOrder(true);
      await api.quiz.saveQuizOrder(order);
      const localResponse = await refetchQuestions();
      setOrder(localResponse.data.map((q) => q._id));
    } catch (err) {
      console.log(err);
    } finally {
      setSavingOrder(false);
    }
  };

  return (
    <AppLayout>
      <Container fluid>
        <Row>
          <Col sm={8}>
            <HeaderContainer bottom={35}>
              <H1>Questions</H1>
              <ButtonComponent small onClick={() => setAddNewModalVisible(true)}>
                Add new +
              </ButtonComponent>
            </HeaderContainer>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="questions">
                {(provided) => (
                  <QuestionsList
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {order.map((questionId, index) => {
                      const question = response.data.find((q) => q._id === questionId);

                      if (!question) return null;

                      return (
                        <Question
                          question={question}
                          openModalWithId={openModalWithId}
                          removeQuizQuestion={removeQuizQuestion}
                          index={index}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </QuestionsList>
                )}
              </Droppable>
            </DragDropContext>
            {getShouldDisplaySaveOrderButton() && (
              <Spacer top={25}>
                <ButtonComponent
                  small
                  onClick={saveNewOrder}
                  loading={savingOrder}
                >
                  Save new order
                </ButtonComponent>
              </Spacer>
            )}
          </Col>
        </Row>
      </Container>
      <CreateNewModal
        questionId={selectedQuestionId}
        onCreate={refetchQuestions}
        visible={addNewModalVisible}
        close={closeModal}
      />
    </AppLayout>
  );
};

export default QuizQuestions;
