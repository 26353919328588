import React from 'react';
import styled from 'styled-components';

import close from '../../assets/icons/close-black.svg';

interface BackdropProps {
  visible: boolean;
}

const Backdrop = styled.div<BackdropProps>`
  transition: .3s ease-in-out;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 200px);
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  opacity: 0;
  visibility: hidden;
  padding: 150px 0 50px;
  
  ${({ visible }) => (visible ? `
    opacity: 1;
    visibility: visible;
  ` : '')}
`;

const Content = styled.div`
  width: 750px;
  background-color: white;
  border-radius: 30px;
  padding: 50px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  
  img {
    width: 25px;
    height: 25px;
  }
`;

interface Props extends BackdropProps {
  closeModal: () => void;
}

const Modal: React.FunctionComponent<Props> = ({ visible, closeModal, children }) => (
  <Backdrop visible={visible}>
    <Content>
      <Header>
        <CloseButton onClick={closeModal}>
          <img src={close} alt="Close" />
        </CloseButton>
      </Header>
      {children}
    </Content>
  </Backdrop>
);

export default Modal;
