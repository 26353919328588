import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { colors } from '../../config/styles';

import heart from '../../assets/icons/heart.svg';
import usericon from '../../assets/icons/profile_inactive_v2.svg';
import usericonpng from '../../assets/icons/users.png';
import logout from '../../assets/icons/logout.svg';
import heartActive from '../../assets/icons/heart-active.svg';
import UserActions from '../../redux/user/actions';
import AuthAction from '../../redux/auth/actions';

import user from '../../assets/icons/profile_inactive.svg';
import profile from '../../assets/icons/profile.svg';

import game from '../../assets/icons/game.svg';
import gameActive from '../../assets/icons/game-active.svg';

import questionActive from '../../assets/icons/question_active.svg';
import questionInactive from '../../assets/icons/question_inactive.svg';
import userDetails from '../../assets/icons/user_details_active.svg';

import userDetailsActive from '../../assets/icons/user_details_active.svg';
import userDetailsInactive from '../../assets/icons/user_details_inactive.svg';

const App = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Sidebar = styled.aside`
  position: fixed;
  width: 200px;
  background-color: ${colors.background};
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 120px);
  left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const MainLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SidebarLink = styled(NavLink)`
  & + & {
    margin-top: 30px;
  }
  
  img.active {
    display: none;
  }
  img:not(.active) {
    display: block;
  }
  
  
  &.link-active {
    img.active {
      display: block;
    }
    img:not(.active) {
      display: none;
    }
  }

  img {
    width: 35px;
    height: 35px;
  }
  
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 50px;
  transition: .1s ease-in-out;
  margin: -15px;
  
  &:hover {
    background-color: rgba(0, 0, 0, .2);
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  padding: 15px;
  border-radius: 50px;
  transition: .1s ease-in-out;
  margin: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, .2);
  }

  img {
    width: 35px;
    height: 35px;
  }
`;

const Content = styled.main`
  background-color: white;
  padding-left: 65px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 60px 65px 60px 265px;
  flex-grow: 1;
`;

const links = [
  { icon: usericon, iconActive: profile, path: '/app/users' },
  { icon: heart, iconActive: heartActive, path: '/app/results' },
  { icon: game, iconActive: gameActive, path: '/app/game-results' },
  { icon: questionInactive, iconActive: questionActive, path: '/app/questions' },
  { icon: userDetailsActive, iconActive: userDetailsInactive, path: '/app/user-feedback' },
];

const AppLayout: React.FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    dispatch(UserActions.removeUser());
    dispatch(AuthAction.logoutUser());
  };

  return (
    <App>
      <Sidebar>
        <MainLinks>
          {links.map((link) => (
            <SidebarLink key={link.path} to={link.path} activeClassName="link-active">
              <img src={link.icon} alt="Link" />
              <img className="active" src={link.iconActive} alt="Link Active" />
            </SidebarLink>
          ))}
        </MainLinks>
        <LogoutButton onClick={handleLogoutClick}>
          <img src={logout} alt="Logout" />
        </LogoutButton>
      </Sidebar>
      <Content>
        {children}
      </Content>
    </App>
  );
};

export default AppLayout;
