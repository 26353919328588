import * as Types from './actionTypes';
import { User } from '../../typings/api';

const saveUser = (user: User): Types.SaveUserAction => ({
  type: Types.SAVE_USER,
  payload: {
    // eslint-disable-next-line no-underscore-dangle
    id: user._id,
    // @ts-ignore
    name: user.name as string,
    email: user.email,
  },
});

const removeUser = (): Types.RemoveUserAction => ({
  type: Types.REMOVE_USER,
});

export default {
  saveUser,
  removeUser,
};
