import { AxiosPromise } from 'axios';
import client from '../core';
import { User } from '../../typings/api';

export interface LoginData {
  email: string;
  password: string;
}

const login = (data: LoginData): AxiosPromise<any> => client.post('auth/login', data);

export interface RegisterData {
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
}

const register = (data: RegisterData): AxiosPromise<any> => client.post('auth/register', data);

export interface ResetPasswordData {
  email: string;
}

const resetPassword = (data: ResetPasswordData): AxiosPromise<any> => client.post('auth/reset-password', data);

export interface SetPasswordData {
  token: string;
  password: string;
  passwordRepeat: string;
}

const setPassword = (data: SetPasswordData): AxiosPromise<any> => client.post('auth/set-password', data);

const getProfile = (): AxiosPromise<User> => client.get('auth/profile');

export default {
  login,
  register,
  resetPassword,
  setPassword,
  getProfile,
};
